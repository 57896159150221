<template>
  <div>
    <component :is="layout">
      <div class="card shadow p-2 m-2 ">
        <span v-if="message">
          <p class="text-center alert-success">{{ message }}</p>
        </span>
        <div class="d-flex p-3 justify-content-between">
          <h3 class="text-center">Guessing Tables</h3>
          <div>
              <button class="btn bg-warning btn-warning " @click="generateGuessingTable()">Generate</button>
              <button class="btn bg-success btn-success" @click="showCreateTable()">
            Create
          </button>
          </div>

        </div>
        <table class="border table text-center table-responsive ">
          <thead>
            <th>Id</th>
            <th>Name</th>
            <th>Panel Count</th>
            <th>Status</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr v-for="guessingTable in guessingTables" :key="guessingTable.id">
              <td>{{ guessingTable.id }}</td>
              <td>{{ guessingTable.name }}</td>
              <td>{{ guessingTable.panel_count }}</td>
              <td
                @click="toggleGuessingTableStatus(guessingTable)"
                v-if="guessingTable.status == 1"
              >
                <button class="btn btn-sm btn-success">Activated</button>
              </td>
              <td @click="toggleGuessingTableStatus(guessingTable)" v-else>
                <button class="btn btn-sm btn-danger">Deactivated</button>
              </td>
              <td>
                <button
                  @click="showUpdateTable(guessingTable)"
                  class="btn btn-primary btn-sm"
                >
                  Edit
                </button>
                <button
                  @click="deleteGuessingTable(guessingTable.id)"
                  class="btn btn-danger btn-sm"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal :showModal="showEditModal">
        <template v-slot:header>
          <h3>{{ guessingTable.name }}</h3>
        </template>
        <template v-slot:body>
          <div class="form-group">
            <label for="">Name</label>
            <input
              type="text"
              v-model="guessingTable.name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">Panel Count</label>
            <input
              type="number"
              v-model="guessingTable.panel_count"
              class="form-control"
            />
          </div>
        </template>
        <template v-slot:footer>
          <button
            @click="updateGuessingTable(guessingTable.id)"
            class="btn btn-success modal-default-button"
          >
            Update
          </button>
          <button
            class="btn btn-danger modal-default-button"
            @click="showEditModal = false"
          >
            close
          </button>
        </template>
      </Modal>

      <Modal :showModal="showCreateModal">
        <template v-slot:header>
          <h3>Create Guessing Table</h3>
        </template>
        <template v-slot:body>
          <div class="form-group">
            <label for="">Name</label>
            <input
              type="text"
              v-model="guessingTable.name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">Panel Count</label>
            <input
              type="number"
              v-model="guessingTable.panel_count"
              class="form-control"
            />
          </div>
        </template>
        <template v-slot:footer>
          <button
            @click="createGuessingTable()"
            class="btn btn-success modal-default-button"
          >
            Create
          </button>
          <button
            class="btn btn-danger modal-default-button"
            @click="showCreateModal = false"
          >
            close
          </button>
        </template>
      </Modal>
    </component>
  </div>
</template>

<script>
import Modal from "../Modal.vue";
import axiosConfig from '../../axiosConfig';
import axiosObject from '../../axiosHandler';
export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      guessingTables: null,
      guessingTable: Object,
      showEditModal: false,
      showCreateModal: false,
      message:null
    };
  },
  created() {
    this.getGuessingTables();
  },
  methods: {
    getGuessingTables() {
      axiosObject.get("/api/admin/guessing_table",axiosConfig)
        .then(
          function (response) {
            this.guessingTables = response.data.guessing_tables;
          }.bind(this)
        )
        .catch(function (error) {
          console.error(error);
        });
    },
    showCreateTable() {
      this.showCreateModal = true;
      this.guessingTable = new Object;
    },
    createGuessingTable() {
      axiosObject.post("/api/admin/guessing_table", this.guessingTable,axiosConfig)
        .then(
          function (response) {
            this.showCreateModal = false;
            this.getGuessingTables();
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    toggleGuessingTableStatus(guessingTable) {
      this.guessingTable = guessingTable;
      this.guessingTable.status = !this.guessingTable.status;

      axiosObject
        .put(
          "/api/admin/guessing_table/" + this.guessingTable.id,
          this.guessingTable,axiosConfig
        )
        .then(
          function (response) {
            this.getGuessingTables();
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    showUpdateTable(guessingTable) {
      this.showEditModal = true;
      this.guessingTable = guessingTable;
    },
    updateGuessingTable(guessingTable) {
      axiosObject
        .put(
          "/api/admin/guessing_table/" + this.guessingTable.id,
          this.guessingTable,axiosConfig
        )
        .then(
          function (response) {
            this.showEditModal = false;
            this.getGuessingTables();
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    deleteGuessingTable(id) {
      if (confirm("Do you really want to delete?")) {
        axiosObject
          .delete("/api/admin/guessing_table/" + id,axiosConfig)
          .then(this.getGuessingTables);
      }
    },
    generateGuessingTable()
    {
        axiosObject.get('/api/generate_guessing_table',axiosConfig).then(function(response){
            this.message = response.data.message
        }.bind(this)).catch(function(error) {
            console.log(error)
        })
    }
  },
};
</script>

<style>
</style>
